import React, { FC } from 'react';
import { Popup } from 'react-map-gl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Trainer } from '../../../api/interface/trainer';

export interface HighlightedContentProps {
  trainerId: Trainer['trainerId'];
  name: string;
  tagline: string;
  latitude: number;
  longitude: number;
}

const StyledPopup = styled(Popup)`
  border-radius: 10px;
`;

const HighlightedContent: FC<HighlightedContentProps> = ({ name, tagline, trainerId, latitude, longitude }) => (
  <StyledPopup latitude={latitude} longitude={longitude}>
    <h3>{name}</h3>
    <p>{tagline}</p>
    <Link to={`trainers/${trainerId}`}>See profile</Link>
  </StyledPopup>
);

export default HighlightedContent;
