import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FBButton } from '../../../../common/components';

export interface ILogoutButton {}

const LogoutButton: FC<ILogoutButton> = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <FBButton shape="round" size="middle" style={{ background: '#ff785b', color: '#fff' }} onClick={handleLogout}>
      Logout
    </FBButton>
  );
};

export default LogoutButton;
