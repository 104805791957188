import React from 'react';
import image1 from '../../../../assets/pic2.jpg';
import image2 from '../../../../assets/pic4.jpg';
import './styles.scss';

const staticData = [
  { title: 'YOGA', image: image1 },
  { title: 'YOGA', image: image2 },
  { title: 'YOGA', image: image1 },
  { title: 'YOGA', image: image2 },
  { title: 'YOGA', image: image1 },
];

const WorkoutsMenu = () => (
  <div className="menu">
    {staticData.map((item) => (
      <div style={{ backgroundImage: `url(${item.image})` }} className="item" key={Math.random()}>
        <div className="content">
          <div className="title">{item.title}</div>
        </div>
      </div>
    ))}
  </div>
);

export default WorkoutsMenu;
