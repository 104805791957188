import React from 'react';

function NoMatch(): JSX.Element {
  return (
    <div>
      <p>NoMatch</p>
    </div>
  );
}

export default NoMatch;
