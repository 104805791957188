import { Trainer, TrainerDetails } from '../../../api/interface/trainer';
import { TrainersActions, TrainersActionsType } from './actions';

export interface TrainerState {
  isLoading: boolean;
  error: string | null;
  trainers: Trainer[];
  trainerDetails: {
    [key: number]: TrainerDetails;
  };
}

const initialState: TrainerState = {
  isLoading: false,
  error: null,
  trainers: [],
  trainerDetails: {},
};

export const trainer = (state: TrainerState = initialState, action: TrainersActionsType): TrainerState => {
  switch (action.type) {
    case TrainersActions.GET_TRAINER_DETAILS_REQUEST:
    case TrainersActions.GET_TRAINERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case TrainersActions.GET_TRAINER_DETAILS_FAILURE:
    case TrainersActions.GET_TRAINERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case TrainersActions.GET_TRAINERS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        trainers: action.data,
      };
    case TrainersActions.GET_TRAINER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        trainerDetails: {
          ...state.trainerDetails,
          [action.data.trainerId]: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
