import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';

export interface AuthContainerProps {
  children: JSX.Element;
}

const AuthContainer: FC<AuthContainerProps> = ({ children }) => {
  const history = useHistory();

  const handleRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="fitnessbuzz.eu.auth0.com"
      clientId="1ESdm1HZXipqJi7PJWLb1ADuCTe0bI1x"
      redirectUri={window.location.origin}
      onRedirectCallback={handleRedirectCallback}
      audience="https://fitness.buzz"
      scope="offline_access profile phone openid"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthContainer;
