import React from 'react';

function BecomeTrainer(): JSX.Element {
  return (
    <div>
      <p>BecomeTrainer</p>
    </div>
  );
}

export default BecomeTrainer;
