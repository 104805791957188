import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetTrainerDetails } from '../../hooks/useGetTrainerDetails';
import { FBSpinner } from '../../../../common/components';
import ImageGallery from './TrainerImageGallery/ImageGallery';
import TrainerMosaic from './TrainerMosaic/TrainerMosaic';
import TrainerAbout from './TreinerAbout/TrainerAbout';

const DetailPage = styled.div`
  width: 70vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const TrainerDetails: FC = () => {
  const { trainerId } = useParams<{ trainerId: string }>();
  const { isLoading, error, trainer } = useGetTrainerDetails(trainerId);
  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return <FBSpinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!trainer) {
    return null;
  }
  const handleClick = () => setIsOpen(true);

  return (
    <DetailPage>
      {isOpen && <ImageGallery trainer={trainer} onOpen={setIsOpen} />}
      <h1>
        {trainer.profile.firstName} {trainer.profile.lastName}
      </h1>
      <TrainerMosaic trainer={trainer} handleClick={handleClick} />
      <TrainerAbout trainer={trainer} />
    </DetailPage>
  );
};

export default TrainerDetails;
