import React from 'react';
import graphic2 from '../../../../assets/train-graphic.png';
import './styles.scss';
import { FBButton } from '../../../../common/components';

const Promo = () => (
  <section className="full-width">
    <h1 className="center">Create your profile, we will find you clients</h1>
    <h2>Fitness Buzz is growing rapidly and always looking for talented people to join our platform!</h2>
    <div className="content">
      <div className="content-item">
        <img src={graphic2} alt="Hit class" className="home-img" />
      </div>
      <div className="content-item">
        <ul>
          <li>Customize and highlight your profile</li>
          <li>Host on your own terms</li>
          <li>View your schedule and manage your clientele according to you</li>
          <li>Set your price for one-to-one or group sessions</li>
          <li>Let your clients find you</li>
          <FBButton
            shape="round"
            size="large"
            style={{
              background: '#ff785b',
              color: '#fff',
              display: 'flex',
              marginTop: '20px',
            }}
            onClick={() => null}
          >
            Join us
          </FBButton>
        </ul>
      </div>
    </div>
  </section>
);

export default Promo;
