import { call, put, takeLatest } from 'redux-saga/effects';
import { getTrainersFailure, getTrainersSuccess, TrainersActions } from './actions';
import { TrainerApi } from '../../../api/Trainer';
import { Trainer } from '../../../api/interface/trainer';

function* getTrainersSaga() {
  try {
    const trainers: Trainer[] = yield call(TrainerApi.getAll);
    yield put(getTrainersSuccess(trainers));
  } catch (e) {
    console.log(e);
    yield put(getTrainersFailure(e.message));
  }
}

export function* watchGetTrainersSaga() {
  yield takeLatest(TrainersActions.GET_TRAINERS_REQUEST, getTrainersSaga);
}
