import { Link } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  title: string;
  link: string;
}

const NavTitle: FC<Props> = ({ title, link }) => (
  <div className="nav-title-container">
    <h1 className="center">{title}</h1>
    <Link className="option" to={link}>
      Explore all &gt;
    </Link>
  </div>
);

export default NavTitle;
