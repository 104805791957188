import { combineReducers } from 'redux';
import user from './user/reducer';
import { trainer } from '../modules/Trainers/redux';

const rootReducer = combineReducers({
  user,
  trainer,
});

export default rootReducer;
