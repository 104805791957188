import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     const errorResponse = error.response;
//     if (
//       errorResponse &&
//       errorResponse.status === 401 &&
//       // eslint-disable-next-line no-underscore-dangle
//       !originalRequest._retry
//     ) {
//       // eslint-disable-next-line no-underscore-dangle
//       originalRequest._retry = true;
//       try {
//         const refreshToken = await Auth.getRefreshToken();
//         if (typeof refreshToken === 'string') {
//           const response = await Auth.refreshAccessToken(refreshToken);
//           await Auth.setAccessToken(response.accessToken);
//
//           originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
//           return axios(originalRequest);
//         }
//         console.log('interceptor error', error);
//         return Promise.reject(error);
//       } catch (e) {
//         console.log('e', e);
//         return Promise.reject(e);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

class HttpClient {
  private static async options<T>(method: Method, token?: string, data?: T, extraHeaders?: Record<string, unknown>): Promise<AxiosRequestConfig> {
    const options: AxiosRequestConfig = {
      method,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        ...extraHeaders,
      },
    };
    if (!data) {
      return options;
    }
    if (options.headers['Content-Type'] === 'multipart/form-data') {
      return {
        ...options,
        data,
      };
    }
    return {
      ...options,
      data: JSON.stringify(data),
    };
  }

  private static async xhr<T>(url: string, config: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await axios(url, config);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async get<ApiResponse>(url: string, token?: string): Promise<ApiResponse> {
    const options = await HttpClient.options('GET', token);
    return HttpClient.xhr(url, options);
  }

  static async post<Payload, ApiResponse>(url = '', payload?: Payload, extraHeaders?: Record<string, unknown>, token?: string): Promise<ApiResponse> {
    const options = await HttpClient.options('POST', token, payload, extraHeaders);
    return HttpClient.xhr(url, options);
  }

  static async patch<Payload, ApiResponse>(url = '', payload: Partial<Payload>, extraHeaders?: Record<string, unknown>, token?: string): Promise<ApiResponse> {
    const options = await HttpClient.options('PATCH', token, payload, extraHeaders);
    return HttpClient.xhr(url, options);
  }

  static async delete<ApiResponse>(url: string, token?: string): Promise<ApiResponse> {
    const options = await HttpClient.options('DELETE', token);
    return HttpClient.xhr(url, options);
  }

  static async postFormData(url: string, file: File, onUploadProgress: (e: ProgressEvent<EventTarget>) => void, token?: string) {
    const formData = new FormData();
    formData.append('file', file as any);

    const response = await axios.post(url, formData, {
      timeout: 15000,
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...{ Authorization: `Bearer ${token}` },
      },
    });
    return response.data;
  }
}

export default HttpClient;
