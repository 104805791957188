import { call, put, takeLatest } from 'redux-saga/effects';
import { updateTrainerProfileFailure, updateTrainerProfileSuccess, UserActions, userProfileFailure, UserProfileRequest, userProfileSuccess } from '../actions';
import { User } from '../../../api/User';
import { UserProfile } from '../../../api/interface/userProfile';
import { ApprovalStatus } from '../../../api/interface/trainer';
import { formatTrainerProfile } from '../utils';

function* getUserProfile({ token }: UserProfileRequest) {
  try {
    const response: UserProfile = yield call(User.getUserProfile, token);
    if (response.profileId) {
      yield put(userProfileSuccess(response));
      if (response.trainerTrainerId && response.approvalStatus && response.approvalStatus === ApprovalStatus.APPROVED) {
        try {
          const trainerResponse = yield call(User.getTrainerProfile, token);
          const trainerProfile = formatTrainerProfile(trainerResponse);
          if (trainerResponse.trainerId) {
            yield put(updateTrainerProfileSuccess(trainerProfile));
          }
        } catch (err) {
          yield put(updateTrainerProfileFailure(err));
        }
      }
    }
  } catch (error) {
    yield put(userProfileFailure(error.message));
  }
}

export default function* getUserProfileSaga() {
  yield takeLatest(UserActions.USER_PROFILE_REQUEST, getUserProfile);
}
