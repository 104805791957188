import { isEmpty } from 'lodash';
import { TrainerDetails } from '../../api/interface/trainer';

const availabilities = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: [],
};

export const formatTrainerProfile = (res: TrainerDetails) => {
  const trainer = { ...res };
  if (trainer && trainer.location) {
    delete trainer.location.geog;
  }

  if (isEmpty(trainer.availability)) {
    trainer.availability = availabilities;
  }

  return trainer;
};
