import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Map } from '../../modules/Maps/components';
import { TrainerList, useGetTrainers } from '../../modules/Trainers';
import { FBSpinner } from '../../common/components';
import './Trainers.style.scss';
import { Trainer } from '../../api/interface/trainer';
import TrainerMarkers from './TrainerMarkers';
import { mediaQueries } from '../../styles/mediaQueries';

const MapContainer = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => {
    if (isVisible) {
      return `   
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      `;
    }
    return `
      display: none;
  `;
  }}
  @media ${mediaQueries.laptop} {
    display: flex;
    flex: 1;
    width: 50%;
    position: fixed;
    top: 70px;
    right: 0;
  }
`;

const MapToggleButton = styled.button`
  position: absolute;
  padding: 10px;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  box-shadow: #aaaaaa 1px 1px 4px;
  border: none;
  z-index: 11;
  background: white;
  @media ${mediaQueries.laptop} {
    display: none;
  }
`;

const Trainers: FC = () => {
  const { isLoading, trainers, error } = useGetTrainers();
  const [isMapVisible, setIsMapVisible] = useState(false);

  if (isLoading) {
    return <FBSpinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleToggleMap = () => {
    const isVisible = isMapVisible;
    setIsMapVisible(!isVisible);
  };

  const handleMarkerClicked = (trainer: Trainer) => {
    console.log('trainer', trainer);
  };

  return (
    <div className="trainers">
      <TrainerList trainers={trainers} />
      <MapContainer isVisible={isMapVisible}>
        <Map>
          <TrainerMarkers trainers={trainers} onMarkerPress={handleMarkerClicked} />
        </Map>
      </MapContainer>
      <MapToggleButton onClick={handleToggleMap}>Show Map</MapToggleButton>
    </div>
  );
};

export default Trainers;
