import React, { FC, useState } from 'react';
import TrainerMarker from './TrainerMarker';
import { Trainer } from '../../../api/interface/trainer';
import HighlightedContent from './HighlightedContent';

export interface TrainerMarkersProps {
  trainers: Trainer[];
  onMarkerPress: (trainer: Trainer) => void;
}

const TrainerMarkers: FC<TrainerMarkersProps> = ({ trainers, onMarkerPress }) => {
  const [highlightedTrainer, setHighlightedTrainer] = useState<Trainer | null>(null);

  const handleMarkerPress = (t: Trainer) => {
    if (highlightedTrainer && highlightedTrainer.trainerId === t.trainerId) {
      setHighlightedTrainer(null);
    } else {
      setHighlightedTrainer(t);
    }
    onMarkerPress(t);
  };

  const filteredTrainers = trainers.filter((trainer) => trainer.location?.latitude && trainer.location?.longitude);
  return (
    <>
      {filteredTrainers.map((trainer) => (
        <TrainerMarker
          key={trainer.trainerId}
          image={trainer.profile.image}
          latitude={trainer.location!.latitude}
          longitude={trainer.location!.longitude}
          onMarkerPress={() => handleMarkerPress(trainer)}
        />
      ))}
      {highlightedTrainer ? (
        <HighlightedContent
          trainerId={highlightedTrainer.trainerId}
          name={`${highlightedTrainer.profile.firstName} ${highlightedTrainer.profile.lastName}`}
          tagline={highlightedTrainer.tagline!}
          latitude={Number(highlightedTrainer.location!.latitude)}
          longitude={Number(highlightedTrainer.location!.longitude!)}
        />
      ) : null}
    </>
  );
};

export default TrainerMarkers;
