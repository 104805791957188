import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import './header.styles.scss';
import LoginButton from '../../../modules/Auth/components/LoginButton';

const Header = () => (
  <div className="header">
    <Link to="/">
      <Logo className="logo" />
    </Link>
    <div className="options">
      <Link className="option" to="/workouts">
        Workouts
      </Link>
      <Link className="option" to="/trainers">
        Trainers
      </Link>
      <Link className="option" to="/become-trainer">
        Host a class
      </Link>
      <LoginButton />
    </div>
  </div>
);

export default Header;
