import React from 'react';
import face from '../../../assets/face1.jpg';
import './styles.scss';

const Testimonials = () => (
  <section className="testimonials">
    <h1 className="center">Trainees testimonials</h1>
    <div className="content">
      <div className="content-item">
        <div className="image-container">
          <img src={face} alt="Fitness buzz" className="face-img" />
        </div>
        <h3>
          &quot;Lorem Ipsum is simply dummy text of the printing and typesetting typesetting industry. Lorem Ipsum has been the industry standard ever since the
          1500s&quot;
        </h3>
        <h3>-Anita-</h3>
      </div>
      <div className="content-item">
        <div className="image-container">
          <img src={face} alt="Fitness buzz" className="face-img" />
        </div>
        <h3>
          &quot;Lorem Ipsum is simply dummy text of the printing and typesetting typesetting industry. Lorem Ipsum has been the industry standard ever since the
          1500s&quot;
        </h3>
        <h3>-Anita-</h3>
      </div>
      <div className="content-item">
        <div className="image-container">
          <img src={face} alt="Fitness buzz" className="face-img" />
        </div>
        <h3>
          &quot;Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard ever since the since the
          1500s&quot;
        </h3>
        <h3>-Anita-</h3>
      </div>
    </div>
  </section>
);

export default Testimonials;
