import React from 'react';
import './styles.scss';
import { FBButton } from '../../common/components';

const Trainer = () => (
  <div className="container-trainer">
    <div className="header-trainer">
      <h2 className="title">Your personal session</h2>
    </div>
    <div className="menu">
      <div className="item">
        <div className="content">
          <a href="/something" role="button" className="title">
            Personal session
          </a>
          <div className="subtitle">hello sub</div>
        </div>
      </div>
      <div className="header-trainer">
        <h2 className="title">Your classes</h2>
        <FBButton shape="round" size="middle" style={{ background: '#ff785b', color: '#fff' }} onClick={() => null}>
          Create new class
        </FBButton>
      </div>
      <div className="item">
        <div className="content">
          <a href="/something" role="button" className="title">
            Hello
          </a>
          <div className="subtitle">hello sub</div>
        </div>
      </div>
    </div>
  </div>
);

export default Trainer;
