import { UserProfile } from '../../api/interface/userProfile';
import { ApprovalStatus, TrainerDetails } from '../../api/interface/trainer';
import { UserActions, UserActionsTypes } from './actions';

export interface UserState extends Omit<UserProfile, 'phoneNumber' | 'profileId' | 'authId' | 'createDateTime' | 'lastChangedDateTime'> {
  authId: UserProfile['authId'] | null;
  error: string | null;
  isLoading: boolean;
  phoneNumber: UserProfile['phoneNumber'] | null;
  profileId: UserProfile['profileId'] | null;
  trainer: TrainerDetails | null;
  approvalStatus: ApprovalStatus | null;
}

const initialState: UserState = {
  authId: null,
  approvalStatus: null,
  aboutMe: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  trainer: null,
  trainerTrainerId: null,
  profileId: null,
  image: null,
  error: null,
  isLoading: false,
};

const user = (state = initialState, action: UserActionsTypes): UserState => {
  switch (action.type) {
    case UserActions.USER_PROFILE_REQUEST:
    case UserActions.UPDATE_TRAINER_PROFILE:
      return { ...state, isLoading: true, error: null };
    case UserActions.USER_PROFILE_SUCCESS:
      return { ...state, ...action.user, isLoading: false, error: null };
    case UserActions.USER_PROFILE_FAILURE:
    case UserActions.UPDATE_TRAINER_PROFILE_FAILURE:
      return { ...state, isLoading: false, error: null };
    case UserActions.UPDATE_TRAINER_PROFILE_SUCCESS:
      return { ...state, trainer: action.data, isLoading: false, error: null };
    default:
      return state;
  }
};

export default user;
