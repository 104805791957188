export const generateGallery = (imagesArr: string[], minLength: number) => {
  const placeholderImg =
    'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1025&q=80';

  if (imagesArr.length >= minLength) {
    return imagesArr;
  }
  const placeholderImages = new Array(minLength - imagesArr.length).fill(placeholderImg);
  return [...imagesArr, ...placeholderImages];
};
