import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../../redux/store';
import { getTrainerDetailsRequest, getTrainersFailure } from '../redux';

export const useGetTrainerDetails = (trainerId: string) => {
  const dispatch = useDispatch();
  const { trainer, isLoading, error } = useSelector((state: RootState) => ({
    isLoading: state.trainer.isLoading,
    error: state.trainer.error,
    trainer: state.trainer.trainerDetails[Number(trainerId)],
  }));

  useEffect(() => {
    if (trainerId) {
      dispatch(getTrainerDetailsRequest(Number(trainerId)));
    } else {
      dispatch(getTrainersFailure('No trainer'));
    }
  }, [trainerId]);

  return {
    isLoading,
    trainer,
    error,
  };
};
