export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  NOT_STARTED = 'NOT_STARTED',
}

export enum YearsOfExperience {
  LESS_THAN_A_YEAR = 'LESS_THAN_A_YEAR',
  TWO_TO_FOUR = 'TWO_TO_FOUR',
  FOUR_PLUS = 'FOUR_PLUS',
  NO_EXPERIENCE = 'NO_EXPERIENCE',
}

export type AvailabilityType = 'earlyMorning' | 'morning' | 'lunch' | 'afternoon' | 'evening';

export interface TrainerAvailability {
  [key: string]: AvailabilityType[];
}

interface Package {
  sessions: string;
  price: string;
  total: string;
}

export interface TrainerPricing {
  priceId?: number;
  oneToOne: number;
  bringAFriend: number;
  currency: string;
  packages: Package[];
}

export type TCategoryNames =
  | 'Aesthetics'
  | 'Body sculpting'
  | 'Effective fat loss'
  | 'Endurance Training'
  | 'Muscle building'
  | 'Strength Conditioning'
  | 'Cardiovascular Training Methods'
  | 'Resistance Training Methods'
  | 'Diet Analysis and nutrition advice'
  | 'Functional Training'
  | 'High Intensity Interval Training (HIIT)'
  | 'Well being'
  | 'Stamina building'
  | 'GP Referral'
  | 'Kickboxing/boxing'
  | 'Hypertrophy'
  | 'Yoga Trapeze Training Confidence'
  | 'Kettlebells'
  | 'Posture'
  | 'Nutrition'
  | 'Pre & Post Natal exercise'
  | 'Boxing'
  | 'Cardio & weight loss'
  | 'Massage Therapy'
  | 'TRX Training'
  | 'Mixed Martial Arts'
  | 'Marathon preparation'
  | 'Post-injury rehabilitation';

export interface ITrainingCategory {
  id: number;
  name: string; // TODO: I wanted to assign to name: TCategoryNames but TS is not happy
}

export interface Trainer {
  trainerId: number;
  tagline: string | null;
  pricePerHourInGBP: number;
  profile: {
    image: string;
    firstName: string;
    lastName: string;
    email?: string;
  };
  location: {
    city: string;
    country: string;
    latitude: string;
    longitude: string;
    geog?: any;
    postcode: string;
    notes: string;
    atClientLocation: boolean;
  } | null;
}

export interface TrainerDetails extends Trainer {
  certificates: string[];
  aboutMe: string | null;
  facebook: string | null;
  instagram: string | null;
  otherSocialLink: string | null;
  gallery: string[];
  locationNotes: string | null;
  availableAtClientLocation: boolean;
  availability: null | TrainerAvailability;
  pricing: null | TrainerPricing;
  category: string[];
  trainingCategories: number[];
  yearsOfExperience: YearsOfExperience | null;
  approvalStatus: ApprovalStatus;
}
