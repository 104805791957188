export interface Auth0User {
  sub: string;
}

export enum AuthActions {
  AUTHENTICATE_USER = 'AUTHENTICATE_USER',
}

export interface AuthenticateUser {
  type: typeof AuthActions.AUTHENTICATE_USER;
  sub: Auth0User;
}

export const authenticateUser = (sub: Auth0User): AuthenticateUser => ({
  sub,
  type: AuthActions.AUTHENTICATE_USER,
});

export type AuthActionsTypes = AuthenticateUser;
