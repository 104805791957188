import { call, put, takeLatest } from 'redux-saga/effects';
import { getTrainerDetailsFailure, GetTrainerDetailsRequest, getTrainerDetailsSuccess, TrainersActions } from './actions';
import { TrainerApi } from '../../../api/Trainer';
import { TrainerDetails } from '../../../api/interface/trainer';

function* getTrainerDetailsSaga({ trainerId }: GetTrainerDetailsRequest) {
  try {
    const trainer: TrainerDetails = yield call(TrainerApi.getById, trainerId);
    yield put(getTrainerDetailsSuccess(trainer));
  } catch (e) {
    console.log(e);
    yield put(getTrainerDetailsFailure(e.message));
  }
}

export function* watchGetTrainerDetailsSaga() {
  yield takeLatest(TrainersActions.GET_TRAINER_DETAILS_REQUEST, getTrainerDetailsSaga);
}
