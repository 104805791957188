import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import Home from './pages/Home';
import Trainers from './pages/Trainers';
import Workouts from './pages/Workouts';
import BecomeTrainer from './pages/BecomeTrainer';
import NoMatch from './pages/NoMatch';
import TrainerDetailPage from './pages/TrainerDetail';
import WorkoutDetail from './pages/WorkoutDetail';
import Header from './pages/components/Header';
import ProtectedRoute from './modules/Auth/components/ProtectedRoute';
import Profile from './pages/Profile';
import Trainer from './pages/Trainer';

const Router = () => (
  <div className="app-container">
    <div className="app-content">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/become-trainer" component={BecomeTrainer} />
        <Route exact path="/trainers" component={Trainers} />
        <Route path="/trainers/:trainerId" component={TrainerDetailPage} />
        <Route exact path="/workouts" component={Workouts} />
        <Route path="/workouts/:workoutId" component={WorkoutDetail} />
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/trainer" component={Trainer} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  </div>
);

export default Router;
