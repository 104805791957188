import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutButton from '../../modules/Auth/components/LogoutButton';
import './styles.scss';
import { RootState } from '../../redux/store';
import { ApprovalStatus } from '../../api/interface/trainer';

const Profile = () => {
  const user = useSelector((state: RootState) => state.user);
  const isApprovedTrainer =
    (user.trainerTrainerId && user.approvalStatus === ApprovalStatus.APPROVED) || (user.trainer && user.trainer.approvalStatus === ApprovalStatus.APPROVED);
  const menuData = [
    {
      title: 'My Profile',
      link: '/my-details',
      subtitle: 'Provide personal details and how we can reach you',
    },
    {
      title: 'Manage trainer profile',
      link: isApprovedTrainer ? '/trainer' : 'https://fitnessbuzz.typeform.com/to/KfZogZXm',
      subtitle: 'Start earning money leading activities you love',
    },
    {
      title: 'Notification',
      link: '/notify',
      subtitle: 'Choose notification preferences and how you want to be contacted',
    },
    {
      title: 'Payments & payouts',
      link: '/payments',
      subtitle: 'Review payments',
    },
    {
      title: 'Invite friends',
      link: '/payments',
      subtitle: 'Share £10 off your first booking with your friends',
    },
  ];

  return (
    <div className="container-profile">
      <h1 className="title">Hi {user.firstName}</h1>
      <div className="menu">
        {menuData.map((item) => (
          <div className="item" key={item.title}>
            <div className="content">
              <Link className="option" to={item.link}>
                {item.title} &gt;
              </Link>
              <div className="subtitle">{item.subtitle}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="logout-container">
        <LogoutButton />
      </div>
    </div>
  );
};

export default Profile;
