import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface FBRoundedImageProps extends ImgHTMLAttributes<HTMLElement> {
  size: number;
}

const ImageContainer = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
`;

const Image = styled.img<{ size: number }>`
  display: block;
  max-width: ${(props) => props.size * 2}px;
  max-height: ${(props) => props.size * 2}px;
  width: auto;
  height: auto;
`;

const FBRoundedImage: FC<FBRoundedImageProps> = ({ size, ...props }) => {
  return (
    <ImageContainer size={size}>
      <Image size={size} {...props} />
    </ImageContainer>
  );
};

export default FBRoundedImage;
