import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import Router from './Router';
import store from './redux/store';
import AuthContainer from './modules/Auth/components/AuthContainer';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthContainer>
        <Router />
      </AuthContainer>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
