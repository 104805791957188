import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FBButton, FBSpinner } from '../../../../common/components';
import { authenticateUser } from '../../redux/actions';
import { userProfileRequest } from '../../../../redux/user/actions';

const LoginButton: FC = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const handleLogin = () => {
    loginWithRedirect();
  };

  const sub = user && user.sub;
  useEffect(() => {
    if (sub) {
      (async function authActions() {
        const token = await getAccessTokenSilently();
        authenticateUser(user);
        dispatch(userProfileRequest(token));
      })();
    }
  }, [sub, user, dispatch]);

  if (isLoading) {
    return <FBSpinner />;
  }

  if (isAuthenticated) {
    return (
      <Link className="option" to="/profile">
        My Account
      </Link>
    );
  }

  return (
    <div>
      <FBButton shape="round" size="middle" style={{ background: '#ff785b', color: '#fff' }} onClick={handleLogin}>
        Login
      </FBButton>
    </div>
  );
};

export default LoginButton;
