import React, { FC } from 'react';
import styled from 'styled-components';
import TrainerCard from './TrainerCard/TrainerCard';
import { Trainer } from '../../../../api/interface/trainer';
import { mediaQueries } from '../../../../styles/mediaQueries';

export interface TrainerListProps {
  trainers: Trainer[];
}

export const TrainerListContainer = styled.ul`
  display: -webkit-flex;
  display: flex;
  flex: 1;
  text-align: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
  @media ${mediaQueries.laptop} {
    flex: 0.5;
    width: 50%;
  }
`;

const TrainerList: FC<TrainerListProps> = ({ trainers }) => (
  <TrainerListContainer>
    {trainers.map((trainer) => (
      <TrainerCard trainer={trainer} key={trainer.trainerId} />
    ))}
  </TrainerListContainer>
);

export default TrainerList;
