import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FBSpinner } from '../../../common/components';

export interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component, ...args }) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <FBSpinner />,
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
