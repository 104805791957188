import { SubmitInterviewPaylaod, UserProfile } from '../interface/userProfile';
import HttpClient from '../HttpClient';
import { Trainer } from '../interface/trainer';

interface TrainerLocationPayload {
  country: string;
  city: string;
  postcode: string;
  lat: number;
  lng: number;
  notes?: string | null;
  atClientLocation: boolean;
}

const routes = {
  myProfile: `${process.env.REACT_APP_API_BASE_URL}/me`,
  trainer: `${process.env.REACT_APP_API_BASE_URL}/me/trainer`,
  location: `${process.env.REACT_APP_API_BASE_URL}/me/trainer/location`,
  availability: `${process.env.REACT_APP_API_BASE_URL}/me/trainer/availability`,
  profileImage: `${process.env.REACT_APP_API_BASE_URL}/me/profile-image`,
};

export class User {
  static getUserProfile(token: string): Promise<UserProfile> {
    return HttpClient.get(routes.myProfile, token);
  }

  static getTrainerProfile(token: string): Promise<Trainer> {
    return HttpClient.get(routes.trainer, token);
  }

  static updateUserProfile(payload: Partial<UserProfile>): Promise<UserProfile> {
    return HttpClient.patch(routes.myProfile, payload);
  }

  static submitTrainerInterview(payload: SubmitInterviewPaylaod): Promise<Partial<UserProfile>> {
    const { authId, ...rest } = payload;

    return HttpClient.post(routes.trainer, rest);
  }

  static updateTrainerProfile(payload: Partial<Trainer>): Promise<Trainer> {
    return HttpClient.patch(routes.trainer, payload);
  }

  static updateTrainerProfileLocation(payload: TrainerLocationPayload): Promise<Partial<Trainer>> {
    return HttpClient.post(routes.location, payload);
  }

  static updateTrainerProfileAvailability(payload: Partial<Trainer>): Promise<Partial<Trainer>> {
    return HttpClient.post(routes.availability, payload);
  }

  static async saveProfileImage(file: File, onUploadProgress: (e: ProgressEvent<EventTarget>) => void): Promise<{ image: string }> {
    return HttpClient.postFormData(routes.profileImage, file, onUploadProgress);
  }
}
