import { Trainer, TrainerDetails } from '../../../api/interface/trainer';

export enum TrainersActions {
  GET_TRAINERS_REQUEST = 'GET_TRAINERS_REQUEST',
  GET_TRAINERS_SUCCESS = 'GET_TRAINERS_SUCCESS',
  GET_TRAINERS_FAILURE = 'GET_TRAINERS_FAILURE',
  GET_TRAINER_DETAILS_REQUEST = 'GET_TRAINER_DETAILS_REQUEST',
  GET_TRAINER_DETAILS_SUCCESS = 'GET_TRAINER_DETAILS_SUCCESS',
  GET_TRAINER_DETAILS_FAILURE = 'GET_TRAINER_DETAILS_FAILURE',
}

interface GetTrainersRequest {
  type: typeof TrainersActions.GET_TRAINERS_REQUEST;
}

export const getTrainersRequest = (): GetTrainersRequest => ({
  type: TrainersActions.GET_TRAINERS_REQUEST,
});

interface GetTrainersSuccess {
  type: typeof TrainersActions.GET_TRAINERS_SUCCESS;
  data: Trainer[];
}

export const getTrainersSuccess = (data: Trainer[]): GetTrainersSuccess => ({
  type: TrainersActions.GET_TRAINERS_SUCCESS,
  data,
});

interface GetTrainersFailure {
  type: typeof TrainersActions.GET_TRAINERS_FAILURE;
  error: string;
}

export const getTrainersFailure = (error: string): GetTrainersFailure => ({
  type: TrainersActions.GET_TRAINERS_FAILURE,
  error,
});

export interface GetTrainerDetailsRequest {
  type: typeof TrainersActions.GET_TRAINER_DETAILS_REQUEST;
  trainerId: Trainer['trainerId'];
}

export const getTrainerDetailsRequest = (trainerId: Trainer['trainerId']): GetTrainerDetailsRequest => ({
  type: TrainersActions.GET_TRAINER_DETAILS_REQUEST,
  trainerId,
});

interface GetTrainerDetailsSuccess {
  type: typeof TrainersActions.GET_TRAINER_DETAILS_SUCCESS;
  data: TrainerDetails;
}

export const getTrainerDetailsSuccess = (data: TrainerDetails): GetTrainerDetailsSuccess => ({
  type: TrainersActions.GET_TRAINER_DETAILS_SUCCESS,
  data,
});

interface GetTrainerDetailsFailure {
  type: typeof TrainersActions.GET_TRAINER_DETAILS_FAILURE;
  error: string;
}

export const getTrainerDetailsFailure = (error: string): GetTrainerDetailsFailure => ({
  type: TrainersActions.GET_TRAINER_DETAILS_FAILURE,
  error,
});

export type TrainersActionsType =
  | GetTrainersRequest
  | GetTrainersSuccess
  | GetTrainersFailure
  | GetTrainerDetailsFailure
  | GetTrainerDetailsRequest
  | GetTrainerDetailsSuccess;
