import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../../../../styles/mediaQueries';
import { TrainerDetails } from '../../../../../api/interface/trainer';
import { FBButton } from '../../../../../common/components';
import { generateGallery } from '../../../../../common/utils/generatePlaceHolderGallery';

interface TrainerMosaicProps {
  trainer: TrainerDetails;
  handleClick: () => void;
}

const Mosaic = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const LeftColumn = styled.div`
  background-position: center;
  background-size: cover;
  img {
    width: 70vw;
    height: 50vh;
  }
  img:hover {
    cursor: pointer;
  }

  @media ${mediaQueries.laptop} {
    width: 40%;
    display: flex;
    justify-content: center;
    height: 50vh;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 98%;
        height: 98.5%;
      }
    }
  }
`;

const RightColumn = styled.div`
  display: none;
  @media ${mediaQueries.laptop} {
    width: 60%;
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 50%;
      img {
        object-fit: cover;
        width: 98%;
        height: 97%;
      }
      img:hover {
        cursor: pointer;
      }
    }
  }
`;

function TrainerMosaic({ trainer, handleClick }: TrainerMosaicProps) {
  return (
    <>
      <Mosaic>
        <LeftColumn>
          <div role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
            <img src={trainer.gallery[0]} alt="trainer" />
          </div>
        </LeftColumn>
        <RightColumn>
          {generateGallery(trainer.gallery, 4).map((image, i) => (
            <div role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleClick} key={i}>
              <img src={image} alt="trainer" />
            </div>
          ))}
        </RightColumn>
      </Mosaic>
      <FBButton shape="round" size="large" onClick={handleClick}>
        Show all photos
      </FBButton>
    </>
  );
}

export default TrainerMosaic;
