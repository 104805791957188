import React from 'react';
import hero from '../../../../assets/hero.png';
import './styles.scss';

const Index = () => (
  <section className="section-primary">
    <div className="inner">
      <div>
        <h1 className="title">Outdoor and</h1>
        <h1 className="sub-title">in-door fitness</h1>
        <h2 className="sub-title">Find workout & personal trainers</h2>
        <h2 className="custom-line-height">near you.</h2>
      </div>
      <div>
        <img src={hero} alt="Boxing" className="home-img" />
      </div>
    </div>
  </section>
);

export default Index;
