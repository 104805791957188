import React, { FC } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components';
import { FBRoundedImage } from '../../../common/components';

export interface TrainerMarkerProps {
  latitude: number | string;
  longitude: number | string;
  onMarkerPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  image: string;
}

const TrainerButton = styled.button`
  background: none;
  border: none;
`;

const TrainerMarker: FC<TrainerMarkerProps> = ({ latitude, longitude, onMarkerPress, image }) => (
  <Marker latitude={Number(latitude)} longitude={Number(longitude)}>
    <TrainerButton onClick={onMarkerPress}>
      <FBRoundedImage src={image} size={40} alt="marker" />
    </TrainerButton>
  </Marker>
);

export default TrainerMarker;
