import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../../redux/store';
import { getTrainersRequest } from '../redux';

export const useGetTrainers = () => {
  const dispatch = useDispatch();
  const { trainers, isLoading, error } = useSelector((state: RootState) => state.trainer);

  useEffect(() => {
    dispatch(getTrainersRequest());
  }, []);

  return {
    isLoading,
    trainers,
    error,
  };
};
