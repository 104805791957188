import React, { FC, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import './Map.styles.scss';

export interface MapProps {
  children: JSX.Element;
}

export const Map: FC<MapProps> = ({ children }) => {
  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: 51.5074,
    longitude: -0.1278,
    zoom: 10,
  });

  return (
    <div className="map-container">
      <ReactMapGL
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        {...viewport}
        width="100%"
        height="100vh"
        mapStyle="mapbox://styles/bandb/ckany5llk67z31ilk9qcb9l51"
      >
        {children}
      </ReactMapGL>
    </div>
  );
};

export default Map;
