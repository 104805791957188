import { UserProfile } from '../../api/interface/userProfile';
import { TrainerDetails } from '../../api/interface/trainer';

export enum UserActions {
  USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST',
  USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS',
  USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE',
  CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE',
  UPDATE_TRAINER_PROFILE = 'UPDATE_TRAINER_PROFILE',
  UPDATE_TRAINER_PROFILE_SUCCESS = 'UPDATE_TRAINER_PROFILE_SUCCESS',
  UPDATE_TRAINER_PROFILE_FAILURE = 'UPDATE_TRAINER_PROFILE_FAILURE',
}

export interface UserProfileRequest {
  type: typeof UserActions.USER_PROFILE_REQUEST;
  token: string;
}

export const userProfileRequest = (token: string): UserProfileRequest => ({
  type: UserActions.USER_PROFILE_REQUEST,
  token,
});

export interface UserProfileSuccess {
  type: typeof UserActions.USER_PROFILE_SUCCESS;
  user: UserProfile;
}

export const userProfileSuccess = (user: UserProfile): UserProfileSuccess => ({
  type: UserActions.USER_PROFILE_SUCCESS,
  user,
});

export interface UserProfileFailure {
  type: UserActions.USER_PROFILE_FAILURE;
  error: string;
}

// TODO: handle errors - typed them
export const userProfileFailure = (error: string): UserProfileFailure => ({
  type: UserActions.USER_PROFILE_FAILURE,
  error,
});

export interface ClearUserProfile {
  type: typeof UserActions.CLEAR_USER_PROFILE;
}

export const clearUserProfile = () => ({
  type: UserActions.CLEAR_USER_PROFILE,
});

export interface UpdateTrainerProfile {
  type: typeof UserActions.UPDATE_TRAINER_PROFILE;
  data: Partial<TrainerDetails>;
}

export interface UpdateTrainerProfileFailure {
  type: typeof UserActions.UPDATE_TRAINER_PROFILE_FAILURE;
  error: string;
}

export const updateTrainerProfileFailure = (error: string): UpdateTrainerProfileFailure => ({
  type: UserActions.UPDATE_TRAINER_PROFILE_FAILURE,
  error,
});

export interface UpdateTrainerProfileSuccess {
  type: typeof UserActions.UPDATE_TRAINER_PROFILE_SUCCESS;
  data: TrainerDetails;
}

export const updateTrainerProfile = (data: Partial<TrainerDetails>): UpdateTrainerProfile => ({
  type: UserActions.UPDATE_TRAINER_PROFILE,
  data,
});

export const updateTrainerProfileSuccess = (data: TrainerDetails): UpdateTrainerProfileSuccess => ({
  type: UserActions.UPDATE_TRAINER_PROFILE_SUCCESS,
  data,
});

export type UserActionsTypes =
  | UserProfileRequest
  | UserProfileSuccess
  | UserProfileFailure
  | ClearUserProfile
  | UpdateTrainerProfile
  | UpdateTrainerProfileSuccess
  | UpdateTrainerProfileFailure;
