import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../../../../styles/mediaQueries';
import { TrainerDetails } from '../../../../../api/interface/trainer';
import { Map } from '../../../../Maps/components';
import TrainerMarker from '../../../../../pages/Trainers/TrainerMarkers/TrainerMarker';

const About = styled.div`
  @media ${mediaQueries.laptop} {
    margin-top: 5em;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const MapDiv = styled.div`
  flex: 1;
  width: 100%;
  height: 50vh !important;
  overflow: hidden;
`;

const TrainerDescription = styled.div`
  flex: 1;
  @media ${mediaQueries.laptop} {
    width: 50%;
  }
`;

interface TrainerAboutProps {
  trainer: TrainerDetails;
}

function TrainerAbout({ trainer }: TrainerAboutProps) {
  const [lat, setLatitude] = useState<string>('');
  const [long, setLongitude] = useState<string>('');
  const [profileImg, setProfileImg] = useState<string>('');

  useEffect(() => {
    if (trainer) {
      setLatitude(trainer.location!.latitude);
      setLongitude(trainer.location!.longitude);
      setProfileImg(trainer.profile.image);
    }
  }, [trainer]);
  return (
    <About>
      <TrainerDescription>
        <h2>ABOUT ME</h2>
        <p>{trainer.aboutMe}</p>
        <h2>ABOUT THE TRAINING</h2>
        <p>
          &quot;Lorem Ipsum is simply dummy text of the printing and typesetting typesetting industry. Lorem Ipsum has been the industry standard ever since the
          1500s&quot;
        </p>
      </TrainerDescription>
      <MapDiv>
        <Map>
          <TrainerMarker latitude={lat} longitude={long} onMarkerPress={() => null} image={profileImg} />
        </Map>
      </MapDiv>
    </About>
  );
}
export default TrainerAbout;
