import React from 'react';
import './Home.styles.scss';
import HeroSection from './components/Hero';
import NavTitle from './components/NavTitle';
import WorkoutsList from './components/WorkoutsListMenu';
import TrainerList from './components/TrainersListMenu';
import Testimonials from '../components/Testimonials';
import Promo from './components/Promo';

function Home(): JSX.Element {
  return (
    <div className="container">
      <HeroSection />
      <section className="section-secondary">
        <NavTitle title="Outdoor workouts" link="/workouts" />
        <WorkoutsList />
      </section>
      <section className="section-secondary">
        <NavTitle title="Top personal trainers" link="/trainers" />
        <TrainerList />
      </section>
      <Promo />
      <Testimonials />
      <section className="footer">
        <h4>Footer</h4>
      </section>
    </div>
  );
}

export default Home;
