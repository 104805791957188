import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Trainer } from '../../../../../api/interface/trainer';
import TrainerCardContent from './TrainerCardContent';
import { FBRoundedImage } from '../../../../../common/components';

export interface TrainerCardProps {
  trainer: Trainer;
}

const Container = styled.li`
  margin: 0 15px 30px;
  width: 25%;
  min-width: 150px;
  padding: 10px;
  box-shadow: 1px 1px 2px 2px #eee;
  border-radius: 5px;
  background: none;
`;

const TrainerCard: FC<TrainerCardProps> = ({ trainer }) => (
  <Container>
    <Link to={`trainers/${trainer.trainerId}`}>
      <FBRoundedImage size={130} src={trainer.profile.image} alt="trainer profile" />
      <TrainerCardContent
        firstName={trainer.profile.firstName}
        lastName={trainer.profile.lastName}
        tagline={trainer.tagline}
        price={trainer.pricePerHourInGBP}
      />
    </Link>
  </Container>
);

export default TrainerCard;
