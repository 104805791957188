import React, { useState } from 'react';
import styled from 'styled-components';
import { TrainerDetails } from '../../../../../api/interface/trainer';
import { FBButton } from '../../../../../common/components';

const Gallery = styled.div`
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 200%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 4%;
    width: 100%;
    .close-btn {
      position: absolute;
      left: 0;
      margin-left: 2.5rem;
      background-color: lightgrey;
    }
  }
`;

const ImageDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    object-fit: cover;
    max-width: 70vw;
    height: 70vh;
  }
`;

interface ImageGalleryProps {
  trainer: TrainerDetails;
  onOpen: (value: boolean) => void;
}

function ImageGallery({ trainer, onOpen }: ImageGalleryProps) {
  const [index, setIndex] = useState(0);

  const setNextPicture = () => {
    if (index < trainer.gallery.length - 1) setIndex(index + 1);
  };
  const setPreviousPitcure = () => {
    if (index > 0) setIndex(index - 1);
  };
  return (
    <Gallery>
      <div className="top">
        <FBButton shape="round" size="large" className="close-btn" onClick={() => onOpen(false)}>
          Close
        </FBButton>
        <p>
          {index + 1} / {trainer.gallery.length}
        </p>
      </div>
      <ImageDiv>
        <FBButton shape="round" size="large" className="btn" onClick={setPreviousPitcure}>
          {'<'}
        </FBButton>
        <img src={trainer.gallery[index]} alt="" />
        <FBButton shape="round" size="large" className="btn" onClick={setNextPicture}>
          {'>'}
        </FBButton>
      </ImageDiv>
    </Gallery>
  );
}

export default ImageGallery;
