import HttpClient from '../HttpClient';
import { YearsOfExperience, Trainer } from '../interface/trainer';

export interface GetTrainerResponse {
  trainerId: number;
  tagline: string | null;
  pricePerHourInGBP: number;
  profile: {
    firstName: string;
    lastName: string;
    image: string;
  };
}

export interface GetExtendedTrainer extends GetTrainerResponse {
  aboutMe: string;
  certificates: string[];
  facebook: string;
  gallery: string[];
  location: {
    city: string;
    country: string;
    locationId: number;
    point: { x: number; y: number };
    postcode: string;
  };
  category: string;
  yearsOfExperience: YearsOfExperience;
  availability?: null | Record<string, unknown>;
}

export class TrainerApi {
  static baseUrl = `${process.env.REACT_APP_API_BASE_URL}/trainers`;

  static getAll(): Promise<GetTrainerResponse[]> {
    return HttpClient.get(TrainerApi.baseUrl);
  }

  static getById(trainerId: Trainer['trainerId']): Promise<GetExtendedTrainer> {
    return HttpClient.get(`${TrainerApi.baseUrl}/${trainerId}`);
  }

  static getTrainerAvailabilityById(trainerId: Trainer['trainerId']): Promise<GetExtendedTrainer> {
    return HttpClient.get(`${TrainerApi.baseUrl}/training-availability/${trainerId}`);
  }

  static updateGallery(trainerId: Trainer['trainerId'], photos: string[]): Promise<string[]> {
    return HttpClient.patch(`${TrainerApi.baseUrl}/${trainerId}/gallery-image`, photos);
  }

  static async saveGalleryPhoto(trainerId: Trainer['trainerId'], file: File, onUploadProgress: (e: ProgressEvent) => void): Promise<string[]> {
    const url = `${TrainerApi.baseUrl}/${trainerId}/gallery-image`;
    return HttpClient.postFormData(url, file, onUploadProgress);
  }
}
