import { applyMiddleware, createStore, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { UserState } from './user/reducer';
import { TrainerState } from '../modules/Trainers/redux';

const logger = createLogger({
  collapsed: true,
  duration: true,
  diff: true,
});

export interface RootState {
  user: UserState;
  trainer: TrainerState;
}

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(rootSaga);

export default store;
