import React from 'react';
import face from '../../../../assets/face1.jpg';
import './styles.scss';

const staticData = [
  { name: 'Peter', image: face },
  { name: 'Anna', image: face },
  { name: 'Kate', image: face },
  { name: 'Tom', image: face },
  { name: 'Michael', image: face },
];

const Index = () => (
  <div className="trainer-list">
    {staticData.map((item) => (
      <div style={{ backgroundImage: `url(${item.image})` }} className="item" key={Math.random()}>
        <div className="content">
          <div className="title">{item.name}</div>
        </div>
      </div>
    ))}
  </div>
);

export default Index;
