import React from 'react';

function Workouts(): JSX.Element {
  return (
    <div>
      <p>Workouts</p>
    </div>
  );
}

export default Workouts;
