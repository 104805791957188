import React, { FC } from 'react';
import styled from 'styled-components';
import { Trainer } from '../../../../../api/interface/trainer';
import { Colors } from '../../../../../styles/Colors';

export interface TrainerCardContentProps {
  firstName: Trainer['profile']['firstName'];
  lastName: Trainer['profile']['lastName'];
  tagline: Trainer['tagline'];
  price: Trainer['pricePerHourInGBP'];
}

const Price = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const Separator = styled.hr`
  border-color: #cccccc33;
  border-width: 1px;
`;

const Tagline = styled.p`
  font-style: italic;
  margin-bottom: 5px;
`;

const TrainerName = styled.h3`
  font-weight: bold;
  text-align: center;
  color: ${Colors.PRIMARY_TEXT};
  margin-bottom: 0;
`;

const TrainerCardContent: FC<TrainerCardContentProps> = ({ firstName, lastName, tagline, price }) => {
  return (
    <div>
      <TrainerName>
        {firstName} {lastName}
      </TrainerName>
      <Tagline>{tagline}</Tagline>
      <Separator />
      <Price>£{price}</Price>
    </div>
  );
};

export default TrainerCardContent;
